<template>
  <multiselect
    name="multi-destinations-select"
    dir='rtl'
    v-model="mSelectedOption"
    :tag-placeholder="placeholder"
    :placeholder="placeholder"
    :custom-label="customLabel"
    track-by="value"
    :options="sourceOptions"
    :multiple="true"
    :searchable="true"
    :taggable="true"
    :showLabels="false"
    :showPointer="false"
    :close-on-select="false"
    :clear-on-select="false"
    :preserve-search="true"
    @input="changedSelection"
    ref="multiSelectInstitutes"
    @open="opened"
    @close="closed"
    :disabled="disabled"
    class="select-multi-dests"
    :closeText="$t('search-result.select-finish')"
    :class="[theme]"
  >
      <template  slot="tag" slot-scope="props">
        <div style="display: flex; align-items: baseline;" @click="close"
          v-if="props.option && mSelectedOption.length>0 && props.option.id === mSelectedOption[0].id">
          <span style="margin-left:10px;">
            <i :class="`fa ${tagIcon}`" aria-hidden="true"></i>
          </span>
          <span class='ellipsisText'>{{ props.option.label }}</span>
          <span
            v-if="mSelectedOption.length > 1"
            class="grey--text text-caption"
          >
            ( +{{ mSelectedOption.length - 1 }} )
          </span>
          <span class="custom__remove" @mousedown.prevent.stop="clearAll(props.search)">&Cross;</span>
        </div>
        <div v-else></div>
        <!-- <div style="display: flex; align-items: baseline;" >
          <span style="margin-left:10px;">
            <i :class="`fa ${tagIcon}`" aria-hidden="true"></i>
          </span>
          {{ props.option.label }}
        </div> -->
      </template>

      <template slot="option" slot-scope="props">
        <span class="optionLabel" style="font-size:1.5rem;">
          <i :class="`fa ${optionIcon}`" aria-hidden="true"></i>
        </span>
        <span class="option__desc">
          <span class="option__title">
            <input
              name="checkbox"
              v-if="Number(isCheckbox) === 1"
              class="checkbox left" type="checkbox"
              :checked="isSelected(props.option)"
              @focus.prevent
              :key="props.option.id"
            />
            {{ props.option.label }}
            <input
              name="checkbox"
              v-if="Number(isCheckbox) === 2"
              class="checkbox right" type="checkbox"
              :checked="isSelected(props.option)"
              @focus.prevent
              :key="props.option.id"
            />
            <input
              name="checkbox"
              v-if="Number(isCheckbox) === 3"
              class="checkbox middle" type="checkbox"
              :checked="isSelected(props.option)"
              @focus.prevent
              :key="props.option.id"
            />
            <span class="hotDestOption" v-if="isHotDestination(props.option.value)">{{$t("home.hot")}}</span>
          </span>
        </span>
      </template>
  </multiselect>
</template>

<script>
// import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
  name: 'SearchMultiSelect',
  components: {
    // Multiselect,
    Multiselect: () => import('@/components/atoms/multiSelect/Multiselect'),
  },
  props: {
    type: {
      type: String,
      default: 'tabs',
    },
    selectedOptions: {
      type: Array,
      default: () => [],
    },
    sourceOptions: {
      type: Array,
      default: () => [],
    },
    customLabel: {
      type: Function,
      default: (option) => option.label,
    },
    placeholder: {
      type: String,
      default: '',
    },
    isCheckbox: {
      type: Number,
      default: 1, // 0 : hidden, 1 : left, 2 : right
    },
    optionIcon: {
      type: String,
      default: '', // fa-map-marker-alt
    },
    tagIcon: {
      type: String,
      default: '', // fa-hotel
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hotDestinations: {
      type: Array,
      default: () => [],
    },
    theme: {
      type: String,
      default: '',
    },
  },
  watch: {
    selectedOptions() {
      const { selectedOptions } = this;
      this.mSelectedOption = selectedOptions.filter((item) => item);
    },
  },
  computed: {
    isSearchable() {
      return this.type === 'mobile';
    },
    bound() {
      return this.styles?.bound ?? '';
    },
    bgColor() {
      return this.styles?.bgColor ?? '#e9ecef';
    },
    computedValue: {
      get() {
        return this.$store.state.selectedOptions;
      },
      set(newVal) {
        this.$store.commit('SET_SELECTED_OPTIONS', newVal);
      },
    },
  },
  data() {
    return {
      mSelectedOption: [],
      isOpen: false,
    };
  },
  created() {
    this.mSelectedOption = this.selectedOptions;
    const queryDestination = this.$route.query?.city?.split(',') ?? [];
    this.mSelectedOption = this.sourceOptions.filter((option) => queryDestination.includes(option.value));
    // if (this.mSelectedOption.length) this.changedSelection();
  },
  methods: {
    isSelected(pItem) {
      return this.mSelectedOption?.some((op) => op.id === pItem.id) ?? false;
    },
    changedSelection() {
      this.$emit('onChangeSelect', this.mSelectedOption);
    },
    opened() {
      setTimeout(() => {
        this.isOpen = true;
      }, 500);
    },
    closed() {
      this.isOpen = false;
    },
    close() {
      if (this.isOpen) {
        this.$refs.multiSelectInstitutes.toggle();
        this.$emit('close');
      }
    },
    isHotDestination(pDestCode) {
      return this.hotDestinations.includes(pDestCode);
    },
    clearAll() {
      this.mSelectedOption = [];
      this.$emit('onChangeSelect', this.mSelectedOption);
    },
  },
};
</script>
<style scoped>
  .st-container {
    overflow: visible;
  }
  .hotDestOption {
    margin-top: -23px;
    display:flex;
    left: 3px;
    height: 18px;
    font-size: 12px;
    position: absolute;
    background-color: #04a1d5;
    color: white;
    border-radius: 20px;
    padding: 6px 6px 5px 6px;
    align-self: center;
    align-items:center;
    animation: blinkingText 2s infinite;
  }
  @keyframes blinkingText {
    0%   {background-color: #04a1d5;}
    25%  {background-color: #8e4860;}
    50%  {background-color: #ef0a1a;}
    75%  {background-color: #8e4860;}
    100% {background-color: #04a1d5;}
  }
  .custom__remove {
    position: absolute;
    left: 30px;
    cursor: pointer;
    font-size: 2rem;
    line-height: 1.5rem;
  }
</style>
<style>
  .filter-tab form .finish-button button.btn {
    height: 2rem;
    width: 70%;
  }
  input[type='search'] {
    margin: 0;
    padding: 0;
    border: 0;
  }

  .filter-tab .st-container .vs__dropdown-toggle {
    border-radius: 50px;
    padding: 0.575rem 1.35rem;
    height: 3rem;
  }

  .vs__selected {
    width: max-content;
  }

  .style-chooser .vs__dropdown-menu li {
    height: 45px;
    line-height: 35px;
    transition: 0.2s;
  }

  .style-chooser .vs__selected-options {
    display: contents;
  }

  .filter-tab form button.btn {
    height: 50px;
  }
  .checkbox {
    position: absolute;
    width: 15px;
  }

  .checkbox.left {
    left: 1vw;
    top: 1em;
  }
  .checkbox.right {
    right: 1vw;
    top: 1em;
  }
  .checkbox.middle {
    right: 40px;
    margin-top: 10px;
  }
  .option__title{
    margin-right: 1vw;
  }
  .ellipsisText {
    max-width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .multiselect {
    border-radius: 25px;
  }

  .select-multi-dests .multiselect__tags {
    border-radius: 25px;
    min-height: 48px;
    background-color: #e9ecef;
    border: 1px solid rgba(60,60,60,.26);
    padding-top: 12px !important;
  }

  .select-multi-dests .multiselect__select {
    top: 6px;
    border-radius: 25px;
  }

  .theme1 .select-multi-dests .multiselect__tags {
    border-radius: 5px;
  }

  .theme1 .select-multi-dests .multiselect__tags {
    background-color: #fff;
  }

  .select-multi-dests .multiselect__input {
    background-color: #f8f9fa;
  }
  .form-row .form-group:first-child .multiselect__placeholder {
    color: black;
  }
  .multiselect__placeholder, .multiselect__tags-wrap {
    font-size: 16px;
  }
  .multiselect__element:hover{
    background-color: #6a9efd;
    color: white;
  }

  .multiselect .text-caption {
    margin-left: 3px;
  }

  .optionLabel {
    margin-left: 10px;
  }

  /* .multi-select-sticky .multiselect.select-multi-dests  .multiselect__content .finish-button {
    position: sticky;
    width: 100%;
    bottom: 0;
    background-color: white;
  } */
  @media (max-width: 767px) {
    .option__title{
      margin-right: 2vw;
    }
    .modal .multiselect .checkbox {
      margin-top: 0px;
    }
    .modal .multiselect .checkbox.middle {
      margin-top: 10px;
    }
    .modal .multiselect .checkbox.left {
      top: 17px;
      margin-left: 5px;
    }
    .modal .multiselect .checkbox.right {
      top: 17px;
      margin-right: 5px;
    }
    .checkbox.middle {
      margin-top: 10px;
      right: 40px;
    }
    .checkbox.left {
      top: 14px;
      margin-left: 5px;
    }
    .checkbox.right {
      top: 14px;
      margin-right: 5px;
    }
    .optionLabel {
      margin-left: 15px;
    }
  }
</style>
